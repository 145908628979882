<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="cancelModal()">Cancel</ion-button>
        </ion-buttons>

        <ion-title>Add Purchase</ion-title>

        <ion-buttons slot="end">
          <!-- <ion-button @click="createTransaction()">Add</ion-button> -->
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <v-form @submit="addTransaction">
        <div class="padding-y-16 padding-x-8">
          <ion-grid>
            <ion-row>
              <!-- Product -->
              <ion-col size="12">
                <base-input label-text="Product *">
                  <v-field
                    name="purchasable_id"
                    v-slot="{ field }"
                    v-model="transaction.purchasable_id"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option
                        v-for="purchasable in purchasables"
                        :key="purchasable.id"
                        :value="purchasable.id"
                      >
                        {{ purchasable.name }} - ${{ purchasable.price_retail }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message
                  name="purchasable_id"
                  class="error-message"
                ></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Price Retail -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Retail Price *">
                  <v-field
                    name="price_retail"
                    v-slot="{ field }"
                    v-model="transaction.price_retail"
                    :rules="required"
                  >
                    <ion-input
                      name="price_retail"
                      v-bind="field"
                      placeholder="0.00"
                      type="number"
                      disabled
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_retail" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Price Final -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Final Price *">
                  <v-field
                    name="price_final"
                    v-slot="{ field }"
                    v-model="transaction.price_final"
                    :rules="required"
                  >
                    <ion-input
                      name="price_final"
                      v-bind="field"
                      placeholder="0.00"
                      type="number"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_final" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Date -->
              <ion-col>
                <base-input label-text="Date *">
                  <v-field
                    name="date"
                    v-slot="{ field }"
                    v-model="transaction.date"
                    :rules="required"
                  >
                    <ion-input
                      name="date"
                      v-bind="field"
                      placeholder=""
                      type="date"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="date" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Memo -->
              <ion-col>
                <base-input label-text="Memo">
                  <v-field
                    name="memo"
                    v-slot="{ field }"
                    v-model="transaction.memo"
                  >
                    <ion-textarea
                      name="memo"
                      :auto-grow="true"
                      rows="1"
                      v-bind="field"
                      placeholder="Internal note about this charge"
                    ></ion-textarea>
                  </v-field>
                </base-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-bottom-24">
            Save
          </ion-button>
        </div>
      </v-form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonTextarea,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    ...mapGetters(["authUser", "globalProperty"]),
  },

  async mounted() {
    await this.fetchPurchasables();
  },

  data() {
    return {
      purchasables: [],
      transaction: {
        user_id: this.$route.params.id,
        purchasable: {},
        type: "line_item",
        is_deposit: 0,
        folio_id: this.$route.params.folio_id,
        date: format(new Date(), "yyyy-MM-dd"),
      },
      required: yup.string().required(),
    };
  },

  watch: {
    "transaction.purchasable_id": function () {
      // Find the Purchasable
      let purchasable = this.purchasables.find(
        (x) => x.id === this.transaction.purchasable_id
      );

      // Set the Parameters around the transaction
      this.transaction.price_retail = purchasable.price_retail * -1;
      this.transaction.price_final = purchasable.price_retail * -1;
      this.transaction.amount = purchasable.price_retail * -1;
      this.transaction.description = purchasable.name;
      this.transaction.created_by_id = this.authUser.id

      // Taxes
      this.transaction.tax_type = purchasable.tax_type;

      if (purchasable.tax_type === "sales")
        this.transaction.price_taxes = this.transaction.price_final * 0.0875;
      else if (purchasable.tax_type === "hotel")
        this.transaction.price_taxes = this.transaction.price_final * 0.1475;
      else this.transaction.price_taxes = 0;

      // Round to decimal places
      this.transaction.price_taxes =
        Math.round(this.transaction.price_taxes * 100) / 100;

      this.transaction.price_total =
        this.transaction.price_final + this.transaction.price_taxes;
      this.transaction.amount =
        this.transaction.price_final + this.transaction.price_taxes;
    },
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async handleCheck(event) {
      this.filters[event.detail.value] = event.detail.checked;
    },

    async fetchPurchasables() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/purchasables?property_id=${this.globalProperty.id}`
        )
        .then(async (response) => {
          this.purchasables = response.data.purchasables;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelModal() {
      this.$emit("cancel-modal");
    },

    async addTransaction() {
      this.$emit("add-purchase", this.transaction);
    },
  },
};
</script>