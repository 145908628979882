<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="cancelModal()">Cancel</ion-button>
        </ion-buttons>

        <ion-title>Add Line Item</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <v-form @submit="addTransaction">
        <div class="padding-y-16 padding-x-8">
          <ion-grid>
            <ion-row>
              <!-- Description -->
              <ion-col>
                <base-input label-text="Description *">
                  <v-field name="description" v-slot="{ field }" v-model="transaction.description" :rules="required">
                    <ion-textarea name="description" :auto-grow="true" rows="1" v-bind="field"
                      placeholder="Credit for Breast Kit Pump"></ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="description" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Amount -->
              <ion-col size="12">
                <base-input label-text="Amount (Positive numbers are Credits, Negative numbers are Charges) *">
                  <v-field name="amount" v-slot="{ field }" v-model="transaction.amount" :rules="required">
                    <ion-input name="amount" v-bind="field" placeholder="0.00" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="amount" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Tax Type -->
              <ion-col size="6">
                <base-input label-text="Tax Type *">
                  <v-field name="tax_type" v-slot="{ field }" v-model="transaction.tax_type" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="exempt">Exempt</option>
                      <option value="sales">Sales Tax</option>
                      <option value="hotel">Hotel Tax</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="tax_type" class="error-message">
                </v-error-message>
              </ion-col>

              <ion-col size="6" v-if="transaction.tax_type === 'hotel'">
                <base-input label-text="Nights (Hotel Tax calc)">
                  <v-field name="hotel_tax_num_nights" v-slot="{ field }" v-model="transaction.hotel_tax_num_nights">
                    <select v-bind="field" class="regular-select">
                      <option :value="1">1 night</option>
                      <option :value="2">2 nights</option>
                      <option :value="3">3 nights</option>
                      <option :value="4">4 nights</option>
                      <option :value="5">5 nights</option>
                      <option :value="6">6 nights</option>
                      <option :value="7">7 nights</option>
                      <option :value="8">8 nights</option>
                      <option :value="9">9 nights</option>
                      <option :value="10">10 nights</option>
                      <option :value="11">11 nights</option>
                      <option :value="12">12 nights</option>
                      <option :value="13">13 nights</option>
                      <option :value="14">14 nights</option>
                      <option :value="15">15 nights</option>
                      <option :value="16">16 nights</option>
                      <option :value="17">17 nights</option>
                      <option :value="18">18 nights</option>
                      <option :value="19">19 nights</option>
                      <option :value="20">20 nights</option>
                      <option :value="21">21 nights</option>
                      <option :value="22">22 nights</option>
                      <option :value="23">23 nights</option>
                      <option :value="24">24 nights</option>
                      <option :value="25">25 nights</option>
                      <option :value="26">26 nights</option>
                      <option :value="27">27 nights</option>
                      <option :value="28">28 nights</option>
                      <option :value="29">29 nights</option>
                      <option :value="30">30 nights</option>
                      <option :value="31">31 nights</option>
                      <option :value="32">32 nights</option>
                      <option :value="33">33 nights</option>
                      <option :value="34">34 nights</option>
                      <option :value="35">35 nights</option>
                      <option :value="36">36 nights</option>
                      <option :value="37">37 nights</option>
                      <option :value="38">38 nights</option>
                      <option :value="39">39 nights</option>
                      <option :value="40">40 nights</option>
                      <option :value="41">41 nights</option>
                      <option :value="42">42 nights</option>
                      <option :value="43">43 nights</option>
                      <option :value="44">44 nights</option>
                      <option :value="45">45 nights</option>
                      <option :value="46">46 nights</option>
                      <option :value="47">47 nights</option>
                      <option :value="48">48 nights</option>
                      <option :value="49">49 nights</option>
                      <option :value="50">50 nights</option>
                      <option :value="51">51 nights</option>
                      <option :value="52">52 nights</option>
                      <option :value="53">53 nights</option>
                      <option :value="54">54 nights</option>
                      <option :value="55">55 nights</option>
                      <option :value="56">56 nights</option>
                      <option :value="57">57 nights</option>
                      <option :value="58">58 nights</option>
                      <option :value="59">59 nights</option>
                      <option :value="60">60 nights</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="hotel_tax_num_nights" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Date -->
              <ion-col>
                <base-input label-text="Date *">
                  <v-field name="date" v-slot="{ field }" v-model="transaction.date" :rules="required">
                    <ion-input name="date" v-bind="field" placeholder="" type="date"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="date" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Memo -->
              <ion-col>
                <base-input label-text="Memo">
                  <v-field name="memo" v-slot="{ field }" v-model="transaction.memo">
                    <ion-textarea name="memo" :auto-grow="true" rows="1" v-bind="field"
                      placeholder="Internal note about this charge"></ion-textarea>
                  </v-field>
                </base-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-bottom-24">
            Save
          </ion-button>
        </div>
      </v-form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonTextarea,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    ...mapGetters(["authUser", "globalProperty"]),
  },

  data() {
    return {
      purchasables: [],
      transaction: {
        purchasable: {},
        type: "line_item",
        tax_type: "exempt",
        hotel_tax_num_nights: 1,
        is_deposit: 0,
        folio_id: this.$route.params.folio_id,
        date: format(new Date(), "yyyy-MM-dd"),
      },
      required: yup.string().required(),
    };
  },

  watch: {
    "transaction.amount": function () {
      this.transaction.amount = Number(this.transaction.amount)

      // Set the Parameters around the transaction
      // Note that the taxes and total will get overridden by server calculations in TransactionHook
      this.transaction.price_retail = this.transaction.amount;
      this.transaction.price_final = this.transaction.amount;
      this.transaction.price_taxes = 0;
      this.transaction.price_total = this.transaction.price_final + this.transaction.price_taxes;
    },
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async handleCheck(event) {
      this.filters[event.detail.value] = event.detail.checked;
    },

    cancelModal() {
      this.$emit("cancel-modal");
    },

    async addTransaction() {
      this.$emit("add-line-item", this.transaction);
    },
  },
};
</script>