<template>
  <ion-item :lines="lines" class="">
    <ion-label>
      <div class="h6" :class="transactionColor">
        {{ transaction.description }}
      </div>
      <div>
        <p v-if="transaction.date">{{ formatDate(transaction.date, "MMM d, yyyy") }}</p>
        <p v-if="transaction.stripe_payment_intent_id">Stripe ID: {{ transaction.stripe_payment_intent_id }} ({{
          transaction.stripe_payment_intent_status }})</p>
      </div>
    </ion-label>


    <ion-label slot="end" class="ion-text-right">
      <!-- Amount -->
      <span :class="transactionColor">
        ${{
          amount.toLocaleString("en", { minimumFractionDigits: 2 })
        }}
      </span>

      <!-- Subtotal -->
      <p :class="transactionColor" v-if="transaction.price_final">
        Subtotal:
        {{ (transaction.price_final) ?
          `${transaction.price_final.toLocaleString("en", { minimumFractionDigits: 2 })}` : ''
        }}
      </p>

      <!-- Refund -->
      <p :class="transactionColor" v-if="transaction.stripe_refund_amount">
        Refund:
        {{
          (transaction.stripe_refund_amount / 100).toLocaleString("en", { minimumFractionDigits: 2 })
        }}
      </p>

      <!-- Taxes -->
      <p v-if="transaction.price_taxes">
        Taxes:
        {{ (transaction.price_taxes) ?
          `${transaction.price_taxes.toLocaleString("en", { minimumFractionDigits: 2 })}` : ''
        }}
      </p>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";
import { formatDate } from "@/util/helpers";

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonLabel,
  },
  computed: {
    transactionColor() {
      if (this.transaction.amount >= 0) return "green";
      else return "";
    },

    /*
    * Calculates the amount based on if this is a charge and if there is a refund
    * If this is a line item, return the regular amount
    */
    amount() {
      if (this.transaction.type === 'charge') {
        if (this.transaction.stripe_refund_amount)
          return (this.transaction.stripe_charge_amount - this.transaction.stripe_refund_amount) / 100
        else
          return (this.transaction.stripe_charge_amount) / 100
      }

      return this.transaction.amount
    }
  },
  data() {
    return {
      formatDate,
    };
  },
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>